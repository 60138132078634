import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import useDeviceDetect from "../service/useDeviceDetect"

const MedicalInsuranceTpa = ({
        data: {
            allNodeTemplatePages: {
                edges
            }
        },
    }) => {

    const defaultInsuranceImage = "https://via.placeholder.com/150x80"

    const [activeTab, setActiveTab] = useState(1)

    const { isMobile } = useDeviceDetect();
    const [pageData] = edges
    const metaTag = [];
    const pageDetails = pageData?.node?.relationships?.components

    // undertaking text
    const undertakingText = pageDetails?.[5]?.field_text?.processed
    // finding title and summary
    const upperTitleSection = pageDetails.find(el => el.name === "main_title_and_summary");
    const upperTitleSectionData = typeof (upperTitleSection?.relationships?.components) !== "undefined" && Array.isArray(upperTitleSection?.relationships?.components) && upperTitleSection?.relationships?.components[0]
    const title = typeof (upperTitleSectionData?.field_title) !== "undefined" && upperTitleSectionData?.field_title.length > 0 ? upperTitleSectionData?.field_title : ""
    const summary = typeof (upperTitleSectionData?.text?.processed) !== "undefined" && upperTitleSectionData?.text?.processed.length > 0 ? upperTitleSectionData?.text?.processed : ""

    // banner section
    const bannerSection = pageDetails.find(el => el.name === "insurance_main_image");
    const bannerSectionData = typeof (bannerSection?.relationships?.components) !== "undefined" && Array.isArray(bannerSection?.relationships?.components) && bannerSection?.relationships?.components[0]
    const bannerImage = isMobile === true ? bannerSectionData?.relationships?.image?.uri?.url : bannerSectionData?.relationships?.image?.uri?.url
    const bannerAlt = typeof (bannerSectionData?.image?.alt) !== "undefined" && bannerSectionData?.image?.alt.length > 0 ? bannerSectionData?.image?.alt : "Hinduja Hospital"
    

    //policy section 
    const policySection = pageDetails.find(el => el.name === "policy_title_and_summary");
    const policySectionData = typeof (policySection?.relationships?.components) !== "undefined" && Array.isArray(policySection?.relationships?.components) && policySection?.relationships?.components[0]
    const heading = typeof (policySectionData?.field_title) !== "undefined" && policySectionData?.field_title.length > 0 ? policySectionData?.field_title : ""
    const description = typeof (policySectionData?.text?.processed) !== "undefined" && policySectionData?.text?.processed.length > 0 ? policySectionData?.text?.processed : ""


    // tabs section
    const tabsSection = pageDetails.find(el => el.name === "Tabular Format");
    const tabsSectionData = typeof (tabsSection?.relationships?.components) !== "undefined" && Array.isArray(tabsSection?.relationships?.components) && tabsSection?.relationships?.components


    // insurance section
    const insuranceSection = pageDetails.find(el => el.field_name === "empanelled_titled_section");
    const insuranceTitle = typeof (insuranceSection?.field_title) !== "undefined" && insuranceSection?.field_title.length > 0 && insuranceSection?.field_title
    const insuranceArray = typeof (insuranceSection?.relationships?.field_components) !== "undefined" && Array.isArray(insuranceSection?.relationships?.field_components) && insuranceSection?.relationships?.field_components
    const endText = typeof (insuranceSection?.field_subtitle) !== "undefined" && insuranceSection?.field_subtitle.length > 0 && insuranceSection?.field_subtitle

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/about-through-the-ages.css", "/assets/css/listing.css", "/assets/css/accessibility.css"],
                    }}
                    tags={metaTag}
                />
                <main className="innerpage pt-first-section">
                    <section className="breadcrumbs">
                    </section>
                    <section class="inner_common_banner">
                        <img src={bannerImage} alt={bannerAlt} className="banner_img lazyload" />
                        <div className="banner_content text_left text_dark">
                            <div className="container">
                                <h1>{title.length > 0 ? title : "Medical Insurance TPA"}</h1>
                                <p className="subhead" dangerouslySetInnerHTML={{ __html: summary.length > 0 ? summary : "" }} />
                            </div>
                        </div>
                    </section>
                    <section className="section_bg pt-first-section pb-section about-ages">
                        <div className="container aboutContainer">
                            <ul className="nav nav-tabs gallery-tabs" role="tablist">
                                {
                                    tabsSectionData.map((el, index) => {
                                        return (
                                            <li role="presentation" className="nav-item" key={index}>
                                                <a className={`nav-link ${activeTab === index + 1 ? 'active' : ""}`} data-toggle="tab" href={`#${el?.field_title}`} role="tab" aria-controls={el?.field_title} aria-selected="true" onClick={() => setActiveTab(index + 1)}>{el?.field_title}</a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="container">
                            <div className="tab-content mt-3">
                                {
                                    Array.isArray(tabsSectionData) && tabsSectionData.map((el, index) => {
                                        return (
                                            <div className={`tab-pane ${activeTab === index + 1 ? 'active' : ""} `} id={el?.field_title} role="tabpanel" aria-labelledby={el?.field_title} key={index}>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div dangerouslySetInnerHTML={{ __html: el?.text.processed }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section>
                    <section className="list_tpa">
                        <div className="container">
                            <div className="section-head">
                                <h3>{insuranceTitle || ""}</h3>
                            </div>
                            <div className="row">
                                {
                                    (Array.isArray(insuranceArray) && insuranceArray.length > 0) &&
                                    insuranceArray.map((el, index) => {
                                      
                                        const image = el?.relationships?.image?.uri?.url
                                        const redirectUrl = el?.relationships?.pdf?.url
                                        return (
                                            <div key={index} className="col-lg-3 col-md-4 mb-4">
                                                <div className="card">
                                                    <a href={redirectUrl || "#"} target={redirectUrl ? "_blank" : '_self'}>
                                                        {
                                                            image ?
                                                                <img src={image} alt={el?.image?.alt} className="banner_img" />
                                                                :
                                                                <img src={defaultInsuranceImage} alt="paramounttpa" title="paramounttpa" />
                                                        }
                                                        <span>{el?.title || ""}</span>
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="col-md-12">
                                    <p dangerouslySetInnerHTML={{ __html: endText || "" }}></p>
                                </div>
                                <div className="col-md-12">
                                    <p dangerouslySetInnerHTML={{ __html: undertakingText || "" }}></p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export default MedicalInsuranceTpa
export const query = graphql`
query medicalInsuranceTPAServices {
    allNodeTemplatePages(
      filter: {path: {alias: {regex: "/medical-insurance-tpa$/"}}}
    ) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          metatag {
            attributes {
                content
                href
                name
                property
                rel
            }
          }
          relationships {
            components: field_component_type {
              __typename
              ...ParagraphHtmlText
              ...ParagraphSectiontpa
              ... on paragraph__title_and_description_components {
                id
                field_title
                field_subtitle
                field_link {
                  uri
                  title
                }
                relationships {
                  field_components {
                    ...ParagraphImageWithTitleAndDescriptiontpa
                  }
                }
                field_name
              }
            }
          }
        }
      }
    }
  }
  
  fragment ParagraphSectiontpa on paragraph__section {
    id
    name: field_name
    relationships {
      components: field_components {
        __typename
        ...ParagraphTitleAndDescriptiontpa
        ...ParagraphImageComponenttpa
      }
    }
  }
  
  fragment ParagraphTitleAndDescriptiontpa on paragraph__title_and_description {
    id
    field_title
    text: field_description {
      processed
    }
    field_cta_link {
      title
      uri
    }
  }
  
  fragment ParagraphImageComponenttpa on paragraph__image_component {
    id
    image: field_image {
      alt
    }
    relationships {
      image: field_image {
        id
        uri {
          value
          url
        }
      }
    }
  }
  
  fragment ParagraphImageWithTitleAndDescriptiontpa on paragraph__image_with_title_and_description {
    id
    title: field_title
    description: field_content {
      processed
    }
    image: field_image {
      alt
    }
    alignment: field_alignment
    relationships {
      image: field_image {
        id
        uri {
          value
          url
        }
      }
      pdf: field_pdf_upload {
        url
      }
    }
  }
  
  fragment ParagraphHtmlText on paragraph__html_text {
    field_text {
      processed
    }
  }
  
                                `

